<script setup>
import AppTopBar from "~/components/AppTopBar.vue";

const prefetchIngredientsTranslations = useIngredientsTranslationsPrefetch();
const prefetchIngredientsMap = useIngredientsMapPrefetch();
const prefetchRecyclingMap = useRecyclingMapPrefetch();
const prefetchRecyclingTranslations = useRecyclingTranslationsPrefetch();
const prefetchLabelTranslations = useLabelTranslationsPrefetch();

onMounted(() => {
  prefetchIngredientsMap();
  prefetchIngredientsTranslations();
  prefetchRecyclingMap();
  prefetchRecyclingTranslations();
  prefetchLabelTranslations();
});
</script>
<template>
  <div class="layout-wrapper">
    <link rel="preload" as="image" href="/flags/aud.svg" />
    <link rel="preload" as="image" href="/flags/eur.svg" />
    <link rel="preload" as="image" href="/flags/nzd.svg" />
    <link rel="preload" as="image" href="/flags/usd.svg" />
    <AuthGuard>
      <app-top-bar></app-top-bar>

      <ClientOnly>
        <notifications width="600px" position="top center">
          <template #body="props">
            <div class="pinotqr-notification" :class="props.item.type">
              <div class="icon">
                <font-awesome
                  v-if="props.item.type === 'success'"
                  icon="check"
                  class="pinotqr-notification-icon"
                />
                <font-awesome
                  v-else
                  icon="xmark"
                  class="pinotqr-notification-icon"
                />
              </div>
              <div v-html="props.item.text" />
            </div>
          </template>
        </notifications>
      </ClientOnly>

      <div class="layout-main-container">
        <div class="layout-main">
          <ClientOnly>
            <router-view></router-view>
          </ClientOnly>
        </div>
      </div>
    </AuthGuard>
    <ClientOnly>
      <cookie></cookie>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped></style>
